.Contact-container {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.Info-container{
    display: flex;
    flex: 60%;
    padding: 4%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Contact-list{
    display: flex;
    flex: 50%;
    padding: 30px;
}

.Icon{
    color: orange;
}

.Icon-text{
    color: aliceblue;
    transform: scale(1.2);
    font-family: Acron;
    padding-right: 2%;
}

.Contact-header {
    color: orange;
    font-size: 400%;
    font-family: Acron;
    text-align: center;
}

.Contact-text{
    color: white;
    font-family: Acron;
    word-spacing: 1.5;
    font-size: 30px;
}
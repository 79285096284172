.App-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: white;
    padding: 1%;
    background-color: transparent;
    font-family: 'Allan' ;
  }

  .Button {
    flex: 1;
    border-width: 0;
    color: rgb(249, 248, 248);
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    margin: 10px;
  }

  .Button-container {
    display: flex;
    flex: 50%;
    justify-content: flex-end;
  }

  .Name {
    margin: 0;
    margin-left: 3%;
    display: flex;
    justify-content: flex-start;
  }

  .Home-container {
    display: flex;
    flex: 60%;
    flex-direction: row;
    align-items: center;
  }

  .Home-link {
    display: flex;
  }
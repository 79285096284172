.School-container{
    display: flex;
    flex: 1;
    background-color: orange;
    flex-direction: column;
}

.MPC{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
}

.MPC-mobile{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
}

.UCSC-logo{
    width: 30%;
    height: 30%;
}

.UCSC-logo-mobile{
    width: 50%;
    height: 50%;
}

.Coursework-container{
    display: flex;
    width: 100%;
}

.Coursework-container-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.CC-container{
    font-size: 30px;
    padding: 20px;
    font-family: Acron;
}

.Award-container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-items: center;
    padding: 2%;
}

.Award-container-mobile{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 10%;
}

.Award-image{
    width: 30%;
    height: 30%;
    border-radius: 10%;
}

.Award-image-mobile{
    width: 70%;
    height: 70%;
    border-radius: 10%;
}
.Main-container{
  display: flex;
  justify-content: space-evenly;
  font-family: 'Arial Narrow Bold';
}

.Main-container-mobile{
  display: flex;
  justify-content: space-evenly;
  font-family: 'Arial Narrow Bold';
  flex-direction: column;
}

.Text{
  display: flex;
  color: rgb(220, 218, 236);
  white-space: pre-line;
  flex-direction: column;
  margin: 30px;
}

.Home-pic{
  border-top-left-radius: 5%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 35%;
  width: 35%;
}

.Home-pic-mobile{
  border-top-left-radius: 5%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 80%;
  width: 80%;
  padding-left: 40px;
}

.Slide-container {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
}

.Slide-container-mobile {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
}

.Text {
    font-size: 20px;
    color: aliceblue;
    max-width: 70vh;
}
.Contact-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.Contact-header {
    color: orange;
    font-size: 400%;
    font-family: Acron;
}
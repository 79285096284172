.About-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 100%;
    max-height: 100%;
    padding: 10px
}
.About-container-mobile{
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    padding: 10px
}